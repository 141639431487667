// import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate, useLocation } from "react-router-dom";
// import action
import { getAdminData } from "src/api/auth";

import isEmpty from "src/lib/isEmpty";
import { isLogin } from "src/lib/localStorage";
const HelperRoute = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  let { restriction, role } = useSelector((state) => state.role);

  const currentLocation = useLocation();

  // function
  useEffect(() => {
    if (isLogin) {
      getAdminData(dispatch);
    }
  }, []);

  useEffect(() => {
    if (isLogin && role) {
      let par = [
        "/profile",
        "/loginHistory",
        "/changePassword",
        "/2fa-admin",
        "/twoFA"
      ];
      let champainPath = [
        "/campaigns-History",
        "/campaigns-accounts",
        "/campaigns-leads",
        "/campaigns-deposit","/api-doc"
      ];
      console.log(
        !champainPath.includes(currentLocation.pathname),
        "champainPath.includes(currentLocation.pathname)"
      );
      if (role && role == "campaigner") {
        if (
          !champainPath.includes(currentLocation.pathname) &&
          !par.includes(currentLocation.pathname)
        ) {
          history("/campaigns-leads");
        }
      }
    }
  }, [currentLocation.pathname, role]);
  return <div />;
};

export default HelperRoute;

let key = {};
// if (NODE_ENV === "production") {

const REACT_APP_MODE = "demo";
if (REACT_APP_MODE === "production") {  
  // console.log("Set Production Config");
  const API_URL = "https://prodapi.shoppyai.pro";

  key = {
    secretOrKey: "FxUum76z",
    API_URL: `${API_URL}`,
    TRADE_URL: "",
    getGeoInfo: "https://ipapi.co/json/",

    socialMedia: {
      facebook: {
        appId: "1034988646970193"
      },
      linkedIn: {
        clientId: "78szlpfkw7ee7s",
        redirectUrl: "https://99893158a13c.ngrok.io/signup",
        oauthUrl:
          "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
        scope: "r_liteprofile%20r_emailaddress",
        state: "123456"
      }
    }
  };
} else if (REACT_APP_MODE === "demo") {
  console.log("Set demo Config");

  const API_URL = "https://shoppyapi.wearedev.team";

  key = {
    secretOrKey: "FxUum76z",
    API_URL: `${API_URL}`,
    TRADE_URL: "http://54.211.230.83:8081/api/trade",
    getGeoInfo: "https://ipapi.co/json/",

    socialMedia: {
      facebook: {
        appId: "1034988646970193"
      },
      linkedIn: {
        clientId: "78szlpfkw7ee7s",
        redirectUrl: "https://99893158a13c.ngrok.io/signup",
        oauthUrl:
          "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
        scope: "r_liteprofile%20r_emailaddress",
        state: "123456"
      }
    }
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  const PORT = 2053;
  key = {
    secretOrKey: "FxUum76z",
    API_URL: `${API_URL}:${PORT}`,
    TRADE_URL: "http://54.211.230.83:8081/api/trade",

    getGeoInfo: "https://ipapi.co/json/",

    socialMedia: {
      facebook: {
        appId: "1034988646970193"
      },
      linkedIn: {
        clientId: "78szlpfkw7ee7s",
        redirectUrl: "https://99893158a13c.ngrok.io/signup",
        oauthUrl:
          "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
        scope: "r_liteprofile%20r_emailaddress",
        state: "123456"
      }
    }
  };
}

export default key;

// import packages
import axios from "axios";

// import lib
import config from "./index";
import { getAuthToken } from "../lib/localStorage";
import { logout } from "src/redux/auth/auth.action";
import { Navigate } from "react-router-dom";

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common["Authorization"] = getAuthToken();

export const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = token;
};


export const handleResp = (respData, type = "success", doc) => {
  try {
    console.log(respData, type, doc,'ssssssssssssssssss');
    if (doc === true && type == "success" && respData && respData.data) {
      return { data: respData.data };
    }
    if (type == "success" && respData && respData.data) {
      return respData.data;
    }
    // else if (
    //   type == "error" &&
    //   respData &&
    //   respData.response &&
    //   respData.response.data && respData.response.status == 401
    // ) {
    //   localStorage.removeItem('admin_token')
    //   logout({}, dispatch)
    //   Navigate('/login')
    //   return respData.response.data;
    // }
    else if (
      type == "error" &&
      respData &&
      respData.response &&
      respData.response.data
    ) {
      return respData.response.data;
    } else {
      return {
        success: false,
        message: "Something went wrong",
      };
    }
  } catch (err) {
    return {
      success: false,
      message: "Something went wrong",
    };
  }
};
export default axios;

import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilMoney,
  cilList,
  cilSpeech,
  cilSettings,
  cilEnvelopeClosed,
  cilStar,
  cilUserFemale,
  cilTransfer,
  cilDataTransferDown,
  cilDataTransferUp,
  cilChartLine,
  cilLoopCircular,
  cilWallet,
  cilGraph,
  cilVerticalAlignCenter,
  cibServerFault,
  cilUserPlus,
  cilBook,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: "User List",
    to: "/User_List",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },

  {
    component: CNavGroup,
    name: "Shops & Orders",
    // to: "/campaigner-list",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavGroup,
        name: "Store Management",
        // to: "/campaigner-list",
        icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
        items: [
          {
            component: CNavItem,
            name: "List Store",
            to: "/List_store",
            icon: <CIcon icon={cilList} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: "List Users Store",
            to: "/Lis_userStore",
            icon: <CIcon icon={cilList} customClassName="nav-icon" />,
          },
        ],
      },
      // {
      //   component: CNavGroup,
      //   name: "Order Management",
      //   // to: "/campaigner-list",
      //   icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
      //   items: [
      {
        component: CNavItem,
        name: "Order Management",
        to: "/list_orders",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      // {
      //   component: CNavItem,
      //   name: "Add Orders",
      //   to: "/add_orders",
      //   icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      // }
      // ],
      // }, 
      // {
      // component: CNavGroup,
      // name: "Package Management",
      // // to: "/campaigner-list",
      // icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
      // items: [

    ],
    // },
    // ],
  },




  {
    component: CNavGroup,
    name: "Products and Packages",
    // to: "/campaigner-list",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      // {
      //   component: CNavGroup,
      //   name: "Category Management",
      //   // to: "/campaigner-list",
      //   icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
      //   items: [
      {
        component: CNavItem,
        name: "Category Management",
        to: "/list_catagory",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
        // }
        // ],
      }, {
        // component: CNavGroup,
        // name: "Product Management",
        // // to: "/campaigner-list",
        // icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
        // items: [
        // {
        component: CNavItem,
        name: "Product Management",
        to: "/List_product/1",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      // ],
      // },

      // {
      //   component: CNavGroup,
      //   name: "Products Details",
      //   // to: "/withdraw-list",
      //   icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
      //   items: [
      {
        component: CNavItem,
        name: "Product Details",
        to: "/Product_details",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },

      {
        component: CNavItem,
        name: "Package Management",
        to: "/List_pakage",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Shipping Package Management",
        to: "/shipList_pakage",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Ads Package Management",
        to: "/AdsList_pakage",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },

    
      // ],
      // },
    ],
  },


  {
    component: CNavGroup,
    name: "Finance",
    // to: "/campaigner-list",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavGroup,
        name: "Deposit Management",
        // to: "/withdraw-list",
        icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
        items: [
          {
            component: CNavItem,
            name: "List Admin Deposit",
            to: "/List_adminDeposite",
            icon: <CIcon icon={cilList} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: "List User Deposit",
            to: "/List_userDeposite",
            icon: <CIcon icon={cilList} customClassName="nav-icon" />,
          },
        ],
      }, {
        component: CNavItem,
        name: "Bank Details Verify",
        to: "/KYCVerify",
        icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
      }, {
        // component: CNavGroup,
        // name: "Withdraw Management",
        // to: "/withdraw-list",
        // icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
        // items: [
        //   {
        component: CNavItem,
        name: "Withdraw Management",
        to: "/withdraw-list",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
        // },
        // ],
      }, {
        // component: CNavGroup,
        // name: "Transaction Management",
        // to: "/transaction-history",
        // icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
        // items: [
        //   {
        component: CNavItem,
        name: "Transaction Management",
        to: "/transaction-list",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
        // },
        // ],
      },

    ],
  },


  // {
  //   component: CNavGroup,
  //   name: "Support",
  //   // to: "/campaigner-list",
  //   icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
  //   items: [
  // {
  //   component: CNavGroup,
  //   name: "FAQ Management",
  //   // to: "/campaigner-list",
  //   icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
  //   items: [

  //   ],
  // }, 
  // {
  //   component: CNavItem,
  //   name: "Site Settings",
  //   to: "/Site_setting",
  //   icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  // },

  , {
    component: CNavGroup,
    name: "Campaign Management",
    // to: "/campaigner-list",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Campaigners",
        to: "/campaigner-list",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Campaign List",
        to: "/campaign-list",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
    ],
  },
  // {
  //   component: CNavGroup,
  //   name: "Email Template",
  //   // to: "/templateList",
  //   icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
  //   items: [


  //   ],
  // },

  {
    component: CNavGroup,
    name: "Support",
    // to: "/campaigner-list",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Support Category",
        to: "/listsupportCatagory",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Support Ticket",
        to: "/list_supportticket",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "FAQ",
        to: "/list_faq",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Email Template",
        to: "/templateList",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Referral",
        to: "/referral",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      }
    ],
  },
  // {
  //   component: CNavGroup,
  //   name: "Referral",
  //   // to: "/campaigner-list",
  //   icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
  //   items: [

  //   ],
  // },

  // ],
  // },










  // {
  //   component: CNavGroup,
  //   name: "Modules",
  //   // to: "/campaigner-list",
  //   icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "List Modules",
  //       to: "/List_modules",
  //       icon: <CIcon icon={cilList} customClassName="nav-icon" />,
  //     },
  //     {
  //       component: CNavItem,
  //       name: "List SubModules",
  //       to: "/List_subModules",
  //       icon: <CIcon icon={cilList} customClassName="nav-icon" />,
  //     }
  //   ],
  // },







  // {
  //   component: CNavItem,
  //   name: "Campaigns History",
  //   to: "/campaigns-History",
  //   icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: "Campaigns Accounts",
  //   to: "/campaigns-accounts",
  //   icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: "API Doc",
    to: "/api-doc",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Campaigns Leads",
    to: "/campaigns-leads",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Campaigns Deposit",
    to: "/campaigns-deposit",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },


  {
    component: CNavGroup,
    name: "Admin Controller",
    // to: "/campaigner-list",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Modules",
        to: "/List_modules",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Sub Modules",
        to: "/List_subModules",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Agent",
        to: "/List_subAdmin",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "User Manager",
        to: "/User_manager",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Site Settings",
        to: "/Site_setting",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
    ],
  }



];

export default _nav;

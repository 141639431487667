import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import nav from "src/_nav";
import { useSelector } from "react-redux";

// import lib
import { isLogin } from "../lib/localStorage";
// Containers
const DefaultLayout = React.lazy(() => import('../layout/DefaultLayout'))
const CampaignerLayout = React.lazy(() => import('../layout/CampaignerLayout'))

const ConditionRoute = (props) => {
  const { type, children } = props;
  const history = useNavigate()
  const currentLocation = useLocation();
  let { restriction, role } = useSelector((state) => state.role);
  const [restrictData, setresData] = useState("loading");

  useEffect(() => {
    if (restriction && restriction.length > 0) {
      fetchfunction();
    }
    let par = ["/profile", "/loginHistory", "/changePassword", "/2fa-admin", "/twoFA"];
    let champainPath = ["/campaigns-History"]

    console.log(restriction, role, 'sakthi');

    if (role && role != "campaigner") {
      if (
        currentLocation.pathname == "/dashboard" ||
        role == "superadmin" ||
        role == "manager" || role == "retention_manager" || role == "support_manager" || role == "conversion_manager" || role == "retention_agent" || role == "support_agent" || role == "conversion_agent" || role == "Admin" || role == "campaigner" ||
        par.includes(currentLocation.pathname)
      ) {
        setresData(true);
      }
    }
    if (role && role == "campaigner") {
      setresData(true);
    }

  }, [restriction]);

  const fetchfunction = () => {
    try {
      if (nav && nav.length > 0) {
        let componentName = "";
        for (let navItem of nav) {
          if (navItem.to == currentLocation.pathname) {
            componentName = navItem.name;
            break;
          } else if (navItem.items && navItem.items.length > 0) {
            for (let subNavItem of navItem.items) {
              if (subNavItem.to == currentLocation.pathname) {
                componentName = subNavItem.name;
                break;
              }
            }
          }
        }
        console.log("componentName", componentName);
        console.log("restriction", restriction);

        if (componentName != "" && restriction && restriction.length > 0) {
          let isRestriction = restriction.find(
            (el) => el.name == componentName
          );
          console.log("isRestriction", isRestriction);
          if (isRestriction) {
            setresData(true);
            return;
          }
        }

        setresData(false);
      }
    } catch (err) {
      console.log(err, "-------errorconditin");
    }
  };

  console.log(restrictData, "SAKTHI56", children);
  console.log(isLogin(), type,"isLoginisLoginisLogin");
  if (type === "private" && isLogin() !== true) {
    return <Navigate to="/login" />;
  } else if (restrictData == "loading" && type !== "auth") {
    return <div>Loading....</div>;
  } else if (type === "auth" && isLogin() === true) {
    return <Navigate to="/dashboard" />;
  } else if (restrictData == false) {
    return <Navigate to="/404" />;
  }
  if (role && role == "campaigner") return <CampaignerLayout />;

  return type === "auth" ? children : <DefaultLayout />;
};

ConditionRoute.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default React.memo(ConditionRoute);

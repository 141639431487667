//import config
import axios from '../config/axios'
import config from '../config'
import { handleResp } from '../config/axios'
import { ROLE_DATA } from 'src/redux/role/type'
import { role } from 'src/redux/role/role.action'
export const loginApi = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `${config.API_URL}/api/admin/login`,
      data,
    })
    return handleResp(respData, 'success')
  } catch (err) {
    return handleResp(err, 'error')
  }
}

export const forgotPasswordApi = async (data) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `api/admin/forgotPassword`,
      data,
    })
    return handleResp(respData, 'success')
  } catch (err) {
    return handleResp(err, 'error')
  }
}

export const resetPasswordApi = async (data) => {
  try {
    let respData = await axios({
      method: 'post',
      url: `api/admin/resetPassword`,
      data,
    })
    return {
      status: 'success',
      loading: false,
      message: respData.data.message,
    }
  } catch (err) {
    handleResp(err, "error");
    return {
      status: 'failed',
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    }
  }
}

export const getGeoInfoData = async (data) => {
  try {
    let respData = await axios({
      method: 'get',
      url: `${config.getGeoInfo}`,
    })

    return {
      success: true,
      result: respData.data,
    }
  } catch (err) {
    return {
      success: false,
      error: err.response.data.errors,
    }
  }
}

export const getAdminData = async (dispatch) => {
  try {
    let respData = await axios({
      method: 'get',
      url: `api/admin/getadmindetail`,
    })










    console.log(respData, 'ssssssssssssssss1111111111111');
    role(
      {
        role: respData.data.result.role,
        name: respData.data.result.name,
        restriction: respData.data.result.restriction,
        member: respData.data.result.members,
        id: respData.data.result._id,
        agentId:respData.data.result.agentId,
        managerId:respData.data.result.managerId

      },
      dispatch,
    )
    // dispatch(setdata(respData.data.result))
  } catch (err) {
    return handleResp(err, 'error')
  }
}

export const getPath = async (data) => {
  try {
    let respData = await axios({
      method: 'get',
      url: `/api/admin/getpath`,
      params: data,
    })
    return handleResp(respData, 'success')
  } catch (err) {
    return handleResp(err, 'error')
  }
}